<template>
    <div>
        <div v-for="stock in row.item.stocks"
             :style="'color: '+(stock.items_cnt_avail< stock.warning_lvl ? 'red':'green')">
            {{ stock.stock_name }}: {{ stock.items_cnt_avail || 0 }}/{{ stock.items_cnt || 0 }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>